<template>
   <div>
      <v-row>
         <v-col cols="12">
            <v-form @submit.prevent="submitSearch">
               <v-row>
                  <v-col cols="12">
                     <FormControl inputType="string" v-model="search.email" label="Email"/>
                  </v-col>
                  <v-col cols="12">
                     <v-row>
                        <v-col cols="12 d-flex">
                           <v-btn color="light-green darken-4" x-large class="white--text px-8 mr-3" type="submit" :disabled="isFetching">Search</v-btn>
                           <v-btn color="error" class="px-8 mx-3" x-large @click="clearSearch" :disabled="isFetching">Clear</v-btn>
                        </v-col>
                     </v-row>
                  </v-col>
               </v-row>
            </v-form>
         </v-col>
         <v-col cols="12">
            <Datatable
               :table-headers="tableHeaders"
               :table-data="formData"
               :page="tablePage"
               :page-limit="tablePageLimit"
               :page-limit-options="tablePageLimitOptions"
               :item-total="formItemTotal"
               :is-loading="tableLoading"
               :enable-search="false"
               :enable-export="false"
               :enable-filter="false"
               :enable-date-range="false"
               :enable-clear-all="false"
               :enable-refresh="false"
               :disable-pagination="tableLoading"
               :action-view-route-link="'memberDetail'"
               @options-update="onTableChange"
            />
         </v-col>
      </v-row>
   </div>
</template>

<script>
import FormControl from '@/components/form/FormControl'
import { mapActions, mapState } from 'vuex'
import Datatable from '@/components/Datatable'
import _ from 'lodash'

export default {
   name: 'Member',
   components: {
      Datatable,
      FormControl
   },
   data() {
      return {
         tableHeaders: [
            { text: 'Register date', value: 'create_date', sortable: false },
            { text: 'Username', value: 'username', sortable: false },
            { text: 'Type', value: '_type', sortable: false },
            { text: 'Email', value: 'email', sortable: false },
            { text: 'Phone', value: 'phone', sortable: false },
            { text: '', value: 'actionView', sortable: false, align: 'end' },
         ],
         tableLoading: false,
         tablePage: 1,
         tablePageLimit: 20,
         tablePageLimitOptions: [20, 50, 100],
         formItemTotal: 0,
         formData: [],
         search: {
            email: '',
         },
         dataOptions: {
            type: [
               { value: '', text: 'All' },
               { value: 'normal', text: 'Normal' },
               { value: 'commercial', text: 'Commercial' },
               { value: 'technician', text: 'Technician' },
            ]
         },
      }
   },
   computed: {
      ...mapState({
         isFetching: (state) => state.request.isFetching,
         isSuccess: (state) => state.request.isSuccess,
         isFail: (state) => state.request.isFail,
      }),
   },
   methods: {
      ...mapActions(['sendRequest'], 'request'),
      formatData(data) {
         return _.map(data, (record => {
            const _type = _.chain(this.dataOptions.type)
               .find(['value', record.user_type])
               .get('text')
               .value()
            return {
               ...record,
               _type,
            }
         }))
      },
      async getData() {
         this.tableLoading = true
         let data = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
         }
         if (this.search.email) {
            data = {
               ...data,
               search: this.search.email
            }
         }
         const resp = await this.sendRequest({
            type: 'get_user',
            data,
         })
         if (!resp) {
            this.tableLoading = false
            return
         }
         this.formItemTotal = resp.total
         this.formData = this.formatData(resp.data)
         this.tableLoading = false
      },
      onTableChange(options) {
         if (options.itemsPerPage !== this.tablePageLimit) {
            this.tablePage = 1 // reset to first page if view options changed
         } else {
            this.tablePage = options.page
         }

         if (options.sortBy.length > 0) {
            this.tableSortKey = options.sortBy[0]
         }
         this.tableSortDesc = options.sortDesc.length > 0 ? options.sortDesc[0] : true

         this.tablePageLimit = options.itemsPerPage
         this.getData()
      },
      submitSearch() {
         console.log('submit search', this.search)
         this.getData()
      },
      clearSearch() {
         this.search.email = ''
         this.submitSearch()
      },
   },
   async mounted() {
      await this.getData()
   }
}
</script>
